import { default as emailscgNVgTaHryMeta } from "/build/afa99e53-34cc-426d-9622-d1437abc8818/pages/admin/emails.vue?macro=true";
import { default as integrationsMMgo6dW7K6Meta } from "/build/afa99e53-34cc-426d-9622-d1437abc8818/pages/admin/integrations.vue?macro=true";
import { default as overvieweezI4JbSKrMeta } from "/build/afa99e53-34cc-426d-9622-d1437abc8818/pages/admin/overview.vue?macro=true";
import { default as indexwbzCVqyTLhMeta } from "/build/afa99e53-34cc-426d-9622-d1437abc8818/pages/admin/structures/[id]/index.vue?macro=true";
import { default as subjectsbgiTMofbmiMeta } from "/build/afa99e53-34cc-426d-9622-d1437abc8818/pages/admin/structures/[id]/subjects.vue?macro=true";
import { default as indexjAUfp1CobwMeta } from "/build/afa99e53-34cc-426d-9622-d1437abc8818/pages/admin/structures/index.vue?macro=true";
import { default as index7MZ7CLIVdGMeta } from "/build/afa99e53-34cc-426d-9622-d1437abc8818/pages/admin/users/[id]/index.vue?macro=true";
import { default as addj40EZ8WuS5Meta } from "/build/afa99e53-34cc-426d-9622-d1437abc8818/pages/admin/users/add.vue?macro=true";
import { default as indexf0D5MmVPw7Meta } from "/build/afa99e53-34cc-426d-9622-d1437abc8818/pages/admin/users/index.vue?macro=true";
import { default as adminwQFYz16Ve9Meta } from "/build/afa99e53-34cc-426d-9622-d1437abc8818/pages/admin.vue?macro=true";
import { default as accountdZO6YTMBdeMeta } from "/build/afa99e53-34cc-426d-9622-d1437abc8818/pages/app/account.vue?macro=true";
import { default as entourageMCkF4dow54Meta } from "/build/afa99e53-34cc-426d-9622-d1437abc8818/pages/app/beneficiaries/[id]/edit/entourage.vue?macro=true";
import { default as external_45organisationstKEbJ5HIP7Meta } from "/build/afa99e53-34cc-426d-9622-d1437abc8818/pages/app/beneficiaries/[id]/edit/external-organisations.vue?macro=true";
import { default as healthffhm4aM5DRMeta } from "/build/afa99e53-34cc-426d-9622-d1437abc8818/pages/app/beneficiaries/[id]/edit/health.vue?macro=true";
import { default as infoCuGYmWEMBjMeta } from "/build/afa99e53-34cc-426d-9622-d1437abc8818/pages/app/beneficiaries/[id]/edit/info.vue?macro=true";
import { default as occupationJ4eAcKc3KHMeta } from "/build/afa99e53-34cc-426d-9622-d1437abc8818/pages/app/beneficiaries/[id]/edit/occupation.vue?macro=true";
import { default as tax_45householdye2YnITP43Meta } from "/build/afa99e53-34cc-426d-9622-d1437abc8818/pages/app/beneficiaries/[id]/edit/tax-household.vue?macro=true";
import { default as printm1rHqIZ0BUMeta } from "/build/afa99e53-34cc-426d-9622-d1437abc8818/pages/app/beneficiaries/[id]/print.vue?macro=true";
import { default as index7mERjTxlKgMeta } from "/build/afa99e53-34cc-426d-9622-d1437abc8818/pages/app/beneficiaries/index.vue?macro=true";
import { default as editEuhlh8pQHZMeta } from "/build/afa99e53-34cc-426d-9622-d1437abc8818/pages/app/files/[id]/budget/edit.vue?macro=true";
import { default as editGczFjtQ2UxMeta } from "/build/afa99e53-34cc-426d-9622-d1437abc8818/pages/app/files/[id]/expenses/[eid]/edit.vue?macro=true";
import { default as editCrKKD3i7TOMeta } from "/build/afa99e53-34cc-426d-9622-d1437abc8818/pages/app/files/[id]/followups/[fid]/edit.vue?macro=true";
import { default as addZTvEKMc7xyMeta } from "/build/afa99e53-34cc-426d-9622-d1437abc8818/pages/app/files/[id]/followups/add.vue?macro=true";
import { default as edit3NsLSy8VWmMeta } from "/build/afa99e53-34cc-426d-9622-d1437abc8818/pages/app/files/[id]/help-requests/[subject]/[hrid]/edit.vue?macro=true";
import { default as addtK1WAI0kF5Meta } from "/build/afa99e53-34cc-426d-9622-d1437abc8818/pages/app/files/[id]/help-requests/[subject]/add.vue?macro=true";
import { default as editC1GFz7iakCMeta } from "/build/afa99e53-34cc-426d-9622-d1437abc8818/pages/app/files/[id]/help-requests/housing/[hrid]/edit.vue?macro=true";
import { default as addgFSRUoJjeOMeta } from "/build/afa99e53-34cc-426d-9622-d1437abc8818/pages/app/files/[id]/help-requests/housing/add.vue?macro=true";
import { default as editGby1vZOY96Meta } from "/build/afa99e53-34cc-426d-9622-d1437abc8818/pages/app/files/[id]/income/[iid]/edit.vue?macro=true";
import { default as indextOTEH4BfK2Meta } from "/build/afa99e53-34cc-426d-9622-d1437abc8818/pages/app/files/[id]/index.vue?macro=true";
import { default as addELt1QX9rGFMeta } from "/build/afa99e53-34cc-426d-9622-d1437abc8818/pages/app/files/add.vue?macro=true";
import { default as historyUXAiXCi4HeMeta } from "/build/afa99e53-34cc-426d-9622-d1437abc8818/pages/app/history.vue?macro=true";
import { default as printmX9dSYqlLtMeta } from "/build/afa99e53-34cc-426d-9622-d1437abc8818/pages/app/historyprint/[id]/print.vue?macro=true";
import { default as overviewVvXp1x1dHZMeta } from "/build/afa99e53-34cc-426d-9622-d1437abc8818/pages/app/overview.vue?macro=true";
import { default as partnersFuHQp9gy1vMeta } from "/build/afa99e53-34cc-426d-9622-d1437abc8818/pages/app/partners.vue?macro=true";
import { default as stats0N6HczZaXiMeta } from "/build/afa99e53-34cc-426d-9622-d1437abc8818/pages/app/stats.vue?macro=true";
import { default as indexwf7nAn9ZyWMeta } from "/build/afa99e53-34cc-426d-9622-d1437abc8818/pages/app/structure/index.vue?macro=true";
import { default as subjectsy5AcXTnksnMeta } from "/build/afa99e53-34cc-426d-9622-d1437abc8818/pages/app/structure/subjects.vue?macro=true";
import { default as users3VKJGtnNYQMeta } from "/build/afa99e53-34cc-426d-9622-d1437abc8818/pages/app/users.vue?macro=true";
import { default as appULUkyUDtopMeta } from "/build/afa99e53-34cc-426d-9622-d1437abc8818/pages/app.vue?macro=true";
import { default as adminKzJSHceiLFMeta } from "/build/afa99e53-34cc-426d-9622-d1437abc8818/pages/auth/admin.vue?macro=true";
import { default as loginYwOPDjTb8JMeta } from "/build/afa99e53-34cc-426d-9622-d1437abc8818/pages/auth/login.vue?macro=true";
import { default as logoutcq9rTKkbzYMeta } from "/build/afa99e53-34cc-426d-9622-d1437abc8818/pages/auth/logout.vue?macro=true";
import { default as validate_45token5Qkr9teX18Meta } from "/build/afa99e53-34cc-426d-9622-d1437abc8818/pages/auth/validate-token.vue?macro=true";
import { default as manager0ATreryuplMeta } from "/build/afa99e53-34cc-426d-9622-d1437abc8818/pages/cgu/manager.vue?macro=true";
import { default as user3JSMnse3vLMeta } from "/build/afa99e53-34cc-426d-9622-d1437abc8818/pages/cgu/user.vue?macro=true";
import { default as cgudwAptI89f3Meta } from "/build/afa99e53-34cc-426d-9622-d1437abc8818/pages/cgu.vue?macro=true";
import { default as declaration_45accessibiliteqq8I4BVL1sMeta } from "/build/afa99e53-34cc-426d-9622-d1437abc8818/pages/declaration-accessibilite.vue?macro=true";
import { default as disabled_45accountTBnYQx7vvYMeta } from "/build/afa99e53-34cc-426d-9622-d1437abc8818/pages/error/disabled-account.vue?macro=true";
import { default as invalid_45token1eYoQOwHU6Meta } from "/build/afa99e53-34cc-426d-9622-d1437abc8818/pages/error/invalid-token.vue?macro=true";
import { default as unknown_45user_45icvbOhM23CbSMeta } from "/build/afa99e53-34cc-426d-9622-d1437abc8818/pages/error/unknown-user-ic.vue?macro=true";
import { default as unknown_45userrefVVnEazPMeta } from "/build/afa99e53-34cc-426d-9622-d1437abc8818/pages/error/unknown-user.vue?macro=true";
import { default as indexNn1V61M4HiMeta } from "/build/afa99e53-34cc-426d-9622-d1437abc8818/pages/index.vue?macro=true";
import { default as mentions_45legalesRRvlk6DmAuMeta } from "/build/afa99e53-34cc-426d-9622-d1437abc8818/pages/mentions-legales.vue?macro=true";
import { default as politique_45confidentialitewxGLCX9bXcMeta } from "/build/afa99e53-34cc-426d-9622-d1437abc8818/pages/politique-confidentialite.vue?macro=true";
import { default as registerFLi8gwhhxuMeta } from "/build/afa99e53-34cc-426d-9622-d1437abc8818/pages/register.vue?macro=true";
import { default as statistiquesdKFWpnDzO3Meta } from "/build/afa99e53-34cc-426d-9622-d1437abc8818/pages/statistiques.vue?macro=true";
import { default as stories6ODn2ALV9tMeta } from "/build/afa99e53-34cc-426d-9622-d1437abc8818/pages/stories.vue?macro=true";
export default [
  {
    name: "admin",
    path: "/admin",
    meta: adminwQFYz16Ve9Meta || {},
    component: () => import("/build/afa99e53-34cc-426d-9622-d1437abc8818/pages/admin.vue"),
    children: [
  {
    name: "admin-emails",
    path: "emails",
    component: () => import("/build/afa99e53-34cc-426d-9622-d1437abc8818/pages/admin/emails.vue")
  },
  {
    name: "admin-integrations",
    path: "integrations",
    component: () => import("/build/afa99e53-34cc-426d-9622-d1437abc8818/pages/admin/integrations.vue")
  },
  {
    name: overvieweezI4JbSKrMeta?.name ?? "admin-overview",
    path: "overview",
    meta: overvieweezI4JbSKrMeta || {},
    component: () => import("/build/afa99e53-34cc-426d-9622-d1437abc8818/pages/admin/overview.vue")
  },
  {
    name: "admin-structures-id",
    path: "structures/:id()",
    component: () => import("/build/afa99e53-34cc-426d-9622-d1437abc8818/pages/admin/structures/[id]/index.vue")
  },
  {
    name: "admin-structures-id-subjects",
    path: "structures/:id()/subjects",
    component: () => import("/build/afa99e53-34cc-426d-9622-d1437abc8818/pages/admin/structures/[id]/subjects.vue")
  },
  {
    name: "admin-structures",
    path: "structures",
    component: () => import("/build/afa99e53-34cc-426d-9622-d1437abc8818/pages/admin/structures/index.vue")
  },
  {
    name: "admin-users-id",
    path: "users/:id()",
    component: () => import("/build/afa99e53-34cc-426d-9622-d1437abc8818/pages/admin/users/[id]/index.vue")
  },
  {
    name: "admin-users-add",
    path: "users/add",
    component: () => import("/build/afa99e53-34cc-426d-9622-d1437abc8818/pages/admin/users/add.vue")
  },
  {
    name: "admin-users",
    path: "users",
    component: () => import("/build/afa99e53-34cc-426d-9622-d1437abc8818/pages/admin/users/index.vue")
  }
]
  },
  {
    name: "app",
    path: "/app",
    meta: appULUkyUDtopMeta || {},
    component: () => import("/build/afa99e53-34cc-426d-9622-d1437abc8818/pages/app.vue"),
    children: [
  {
    name: "app-account",
    path: "account",
    component: () => import("/build/afa99e53-34cc-426d-9622-d1437abc8818/pages/app/account.vue")
  },
  {
    name: "app-beneficiaries-id-edit-entourage",
    path: "beneficiaries/:id()/edit/entourage",
    component: () => import("/build/afa99e53-34cc-426d-9622-d1437abc8818/pages/app/beneficiaries/[id]/edit/entourage.vue")
  },
  {
    name: "app-beneficiaries-id-edit-external-organisations",
    path: "beneficiaries/:id()/edit/external-organisations",
    component: () => import("/build/afa99e53-34cc-426d-9622-d1437abc8818/pages/app/beneficiaries/[id]/edit/external-organisations.vue")
  },
  {
    name: "app-beneficiaries-id-edit-health",
    path: "beneficiaries/:id()/edit/health",
    component: () => import("/build/afa99e53-34cc-426d-9622-d1437abc8818/pages/app/beneficiaries/[id]/edit/health.vue")
  },
  {
    name: "app-beneficiaries-id-edit-info",
    path: "beneficiaries/:id()/edit/info",
    component: () => import("/build/afa99e53-34cc-426d-9622-d1437abc8818/pages/app/beneficiaries/[id]/edit/info.vue")
  },
  {
    name: occupationJ4eAcKc3KHMeta?.name ?? "app-beneficiaries-id-edit-occupation",
    path: "beneficiaries/:id()/edit/occupation",
    meta: occupationJ4eAcKc3KHMeta || {},
    component: () => import("/build/afa99e53-34cc-426d-9622-d1437abc8818/pages/app/beneficiaries/[id]/edit/occupation.vue")
  },
  {
    name: "app-beneficiaries-id-edit-tax-household",
    path: "beneficiaries/:id()/edit/tax-household",
    component: () => import("/build/afa99e53-34cc-426d-9622-d1437abc8818/pages/app/beneficiaries/[id]/edit/tax-household.vue")
  },
  {
    name: "app-beneficiaries-id-print",
    path: "beneficiaries/:id()/print",
    meta: printm1rHqIZ0BUMeta || {},
    component: () => import("/build/afa99e53-34cc-426d-9622-d1437abc8818/pages/app/beneficiaries/[id]/print.vue")
  },
  {
    name: index7mERjTxlKgMeta?.name ?? "app-beneficiaries",
    path: "beneficiaries",
    meta: index7mERjTxlKgMeta || {},
    component: () => import("/build/afa99e53-34cc-426d-9622-d1437abc8818/pages/app/beneficiaries/index.vue")
  },
  {
    name: editEuhlh8pQHZMeta?.name ?? "app-files-id-budget-edit",
    path: "files/:id()/budget/edit",
    meta: editEuhlh8pQHZMeta || {},
    component: () => import("/build/afa99e53-34cc-426d-9622-d1437abc8818/pages/app/files/[id]/budget/edit.vue")
  },
  {
    name: editGczFjtQ2UxMeta?.name ?? "app-files-id-expenses-eid-edit",
    path: "files/:id()/expenses/:eid()/edit",
    meta: editGczFjtQ2UxMeta || {},
    component: () => import("/build/afa99e53-34cc-426d-9622-d1437abc8818/pages/app/files/[id]/expenses/[eid]/edit.vue")
  },
  {
    name: "app-files-id-followups-fid-edit",
    path: "files/:id()/followups/:fid()/edit",
    component: () => import("/build/afa99e53-34cc-426d-9622-d1437abc8818/pages/app/files/[id]/followups/[fid]/edit.vue")
  },
  {
    name: "app-files-id-followups-add",
    path: "files/:id()/followups/add",
    component: () => import("/build/afa99e53-34cc-426d-9622-d1437abc8818/pages/app/files/[id]/followups/add.vue")
  },
  {
    name: "app-files-id-help-requests-subject-hrid-edit",
    path: "files/:id()/help-requests/:subject()/:hrid()/edit",
    component: () => import("/build/afa99e53-34cc-426d-9622-d1437abc8818/pages/app/files/[id]/help-requests/[subject]/[hrid]/edit.vue")
  },
  {
    name: "app-files-id-help-requests-subject-add",
    path: "files/:id()/help-requests/:subject()/add",
    component: () => import("/build/afa99e53-34cc-426d-9622-d1437abc8818/pages/app/files/[id]/help-requests/[subject]/add.vue")
  },
  {
    name: "app-files-id-help-requests-housing-hrid-edit",
    path: "files/:id()/help-requests/housing/:hrid()/edit",
    component: () => import("/build/afa99e53-34cc-426d-9622-d1437abc8818/pages/app/files/[id]/help-requests/housing/[hrid]/edit.vue")
  },
  {
    name: "app-files-id-help-requests-housing-add",
    path: "files/:id()/help-requests/housing/add",
    component: () => import("/build/afa99e53-34cc-426d-9622-d1437abc8818/pages/app/files/[id]/help-requests/housing/add.vue")
  },
  {
    name: editGby1vZOY96Meta?.name ?? "app-files-id-income-iid-edit",
    path: "files/:id()/income/:iid()/edit",
    meta: editGby1vZOY96Meta || {},
    component: () => import("/build/afa99e53-34cc-426d-9622-d1437abc8818/pages/app/files/[id]/income/[iid]/edit.vue")
  },
  {
    name: "app-files-id",
    path: "files/:id()",
    component: () => import("/build/afa99e53-34cc-426d-9622-d1437abc8818/pages/app/files/[id]/index.vue")
  },
  {
    name: "app-files-add",
    path: "files/add",
    component: () => import("/build/afa99e53-34cc-426d-9622-d1437abc8818/pages/app/files/add.vue")
  },
  {
    name: "app-history",
    path: "history",
    component: () => import("/build/afa99e53-34cc-426d-9622-d1437abc8818/pages/app/history.vue")
  },
  {
    name: "app-historyprint-id-print",
    path: "historyprint/:id()/print",
    meta: printmX9dSYqlLtMeta || {},
    component: () => import("/build/afa99e53-34cc-426d-9622-d1437abc8818/pages/app/historyprint/[id]/print.vue")
  },
  {
    name: overviewVvXp1x1dHZMeta?.name ?? "app-overview",
    path: "overview",
    meta: overviewVvXp1x1dHZMeta || {},
    component: () => import("/build/afa99e53-34cc-426d-9622-d1437abc8818/pages/app/overview.vue")
  },
  {
    name: "app-partners",
    path: "partners",
    component: () => import("/build/afa99e53-34cc-426d-9622-d1437abc8818/pages/app/partners.vue")
  },
  {
    name: "app-stats",
    path: "stats",
    component: () => import("/build/afa99e53-34cc-426d-9622-d1437abc8818/pages/app/stats.vue")
  },
  {
    name: "app-structure",
    path: "structure",
    component: () => import("/build/afa99e53-34cc-426d-9622-d1437abc8818/pages/app/structure/index.vue")
  },
  {
    name: "app-structure-subjects",
    path: "structure/subjects",
    component: () => import("/build/afa99e53-34cc-426d-9622-d1437abc8818/pages/app/structure/subjects.vue")
  },
  {
    name: "app-users",
    path: "users",
    component: () => import("/build/afa99e53-34cc-426d-9622-d1437abc8818/pages/app/users.vue")
  }
]
  },
  {
    name: "auth-admin",
    path: "/auth/admin",
    meta: adminKzJSHceiLFMeta || {},
    component: () => import("/build/afa99e53-34cc-426d-9622-d1437abc8818/pages/auth/admin.vue")
  },
  {
    name: "auth-login",
    path: "/auth/login",
    meta: loginYwOPDjTb8JMeta || {},
    component: () => import("/build/afa99e53-34cc-426d-9622-d1437abc8818/pages/auth/login.vue")
  },
  {
    name: "auth-logout",
    path: "/auth/logout",
    meta: logoutcq9rTKkbzYMeta || {},
    component: () => import("/build/afa99e53-34cc-426d-9622-d1437abc8818/pages/auth/logout.vue")
  },
  {
    name: "auth-validate-token",
    path: "/auth/validate-token",
    meta: validate_45token5Qkr9teX18Meta || {},
    component: () => import("/build/afa99e53-34cc-426d-9622-d1437abc8818/pages/auth/validate-token.vue")
  },
  {
    name: "cgu",
    path: "/cgu",
    meta: cgudwAptI89f3Meta || {},
    component: () => import("/build/afa99e53-34cc-426d-9622-d1437abc8818/pages/cgu.vue"),
    children: [
  {
    name: "cgu-manager",
    path: "manager",
    component: () => import("/build/afa99e53-34cc-426d-9622-d1437abc8818/pages/cgu/manager.vue")
  },
  {
    name: "cgu-user",
    path: "user",
    component: () => import("/build/afa99e53-34cc-426d-9622-d1437abc8818/pages/cgu/user.vue")
  }
]
  },
  {
    name: "declaration-accessibilite",
    path: "/declaration-accessibilite",
    meta: declaration_45accessibiliteqq8I4BVL1sMeta || {},
    component: () => import("/build/afa99e53-34cc-426d-9622-d1437abc8818/pages/declaration-accessibilite.vue")
  },
  {
    name: "error-disabled-account",
    path: "/error/disabled-account",
    meta: disabled_45accountTBnYQx7vvYMeta || {},
    component: () => import("/build/afa99e53-34cc-426d-9622-d1437abc8818/pages/error/disabled-account.vue")
  },
  {
    name: "error-invalid-token",
    path: "/error/invalid-token",
    meta: invalid_45token1eYoQOwHU6Meta || {},
    component: () => import("/build/afa99e53-34cc-426d-9622-d1437abc8818/pages/error/invalid-token.vue")
  },
  {
    name: "error-unknown-user-ic",
    path: "/error/unknown-user-ic",
    meta: unknown_45user_45icvbOhM23CbSMeta || {},
    component: () => import("/build/afa99e53-34cc-426d-9622-d1437abc8818/pages/error/unknown-user-ic.vue")
  },
  {
    name: "error-unknown-user",
    path: "/error/unknown-user",
    meta: unknown_45userrefVVnEazPMeta || {},
    component: () => import("/build/afa99e53-34cc-426d-9622-d1437abc8818/pages/error/unknown-user.vue")
  },
  {
    name: "index",
    path: "/",
    meta: indexNn1V61M4HiMeta || {},
    component: () => import("/build/afa99e53-34cc-426d-9622-d1437abc8818/pages/index.vue")
  },
  {
    name: "mentions-legales",
    path: "/mentions-legales",
    meta: mentions_45legalesRRvlk6DmAuMeta || {},
    component: () => import("/build/afa99e53-34cc-426d-9622-d1437abc8818/pages/mentions-legales.vue")
  },
  {
    name: "politique-confidentialite",
    path: "/politique-confidentialite",
    meta: politique_45confidentialitewxGLCX9bXcMeta || {},
    component: () => import("/build/afa99e53-34cc-426d-9622-d1437abc8818/pages/politique-confidentialite.vue")
  },
  {
    name: "register",
    path: "/register",
    meta: registerFLi8gwhhxuMeta || {},
    component: () => import("/build/afa99e53-34cc-426d-9622-d1437abc8818/pages/register.vue")
  },
  {
    name: "statistiques",
    path: "/statistiques",
    meta: statistiquesdKFWpnDzO3Meta || {},
    component: () => import("/build/afa99e53-34cc-426d-9622-d1437abc8818/pages/statistiques.vue")
  },
  {
    name: "stories",
    path: "/stories",
    meta: stories6ODn2ALV9tMeta || {},
    component: () => import("/build/afa99e53-34cc-426d-9622-d1437abc8818/pages/stories.vue")
  }
]